var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"margin-bottom-30 margin-top-10"},[_c('vue-good-table',{attrs:{"id":"orders","columns":_vm.selectOrderToDispatchColumns,"rows":_vm.rows,"styleClass":"vgt-table striped bordered","theme":"black-rhino","max-height":"700px","fixed-header":true,"pagination-options":{
			enabled: true,
			mode: 'records',
			perPage: 10,
			position: 'bottom',
			perPageDropdown: [5, 10, 15, 20],
			dropdownAllowAll: false,
			setCurrentPage: 1,
			jumpFirstOrLast: true,
			firstLabel: 'First',
			lastLabel: 'Last',
			nextLabel: 'next',
			prevLabel: 'prev',
			rowsPerPageLabel: 'Rows per page',
			ofLabel: 'of',
			pageLabel: 'page', // for 'pages' mode
			allLabel: 'All',
		}},on:{"on-row-dblclick":_vm.onRowDoubleClick,"on-cell-click":_vm.onCellClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'select')?_c('span',[_c('b-button',{staticClass:"is-small is-success",attrs:{"disabled":_vm.isSelectDisabled}},[_vm._v(" Select ")])],1):_vm._e(),(props.column.field === 'preview')?_c('span',[_c('b-button',{staticClass:"is-small is-info"},[_vm._v("Preview")])],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }