<!-- @format -->

<template>
	<div class="margin-bottom-30 margin-top-10">
		<vue-good-table
			id="orders"
			:columns="selectOrderToDispatchColumns"
			:rows="rows"
			styleClass="vgt-table striped bordered"
			theme="black-rhino"
			max-height="700px"
			:fixed-header="true"
			@on-row-dblclick="onRowDoubleClick"
			@on-cell-click="onCellClick"
			:pagination-options="{
				enabled: true,
				mode: 'records',
				perPage: 10,
				position: 'bottom',
				perPageDropdown: [5, 10, 15, 20],
				dropdownAllowAll: false,
				setCurrentPage: 1,
				jumpFirstOrLast: true,
				firstLabel: 'First',
				lastLabel: 'Last',
				nextLabel: 'next',
				prevLabel: 'prev',
				rowsPerPageLabel: 'Rows per page',
				ofLabel: 'of',
				pageLabel: 'page', // for 'pages' mode
				allLabel: 'All',
			}"
		>
			<template slot="table-row" slot-scope="props">
				<span v-if="props.column.field === 'select'">
					<b-button class="is-small is-success" :disabled="isSelectDisabled">
						Select
					</b-button>
				</span>
				<span v-if="props.column.field === 'preview'">
					<b-button class="is-small is-info">Preview</b-button>
				</span>
				<span v-else>
					{{ props.formattedRow[props.column.field] }}
				</span>
			</template>
		</vue-good-table>
	</div>
</template>

<script>
import useDispatchEditStore from '@/_srcv2/pages/dispatch/edit-dispatch/store/useDispatchEditStore.js'
import AddOrderToDispatchMutation from '@/_srcv2/pages/dispatch/edit-dispatch/graphql/AddOrderToDispatchMutation.graphql'
import GetOrdersToDispatchToSameDispatchAddressQuery from '@/_srcv2/pages/dispatch/graphql/GetOrdersToDispatchToSameDispatchAddressQuery.gql'
import { computed, ref, reactive, onMounted } from '@vue/composition-api'
import { useMutation, useQuery } from '@vue/apollo-composable'
// *----------------------------------------------------------------------------------
import MutateStockCreditAmountMutation from '@/_srcv2/pages/dispatch/graphql/mutateStockCreditAmount.mutation.graphql'
import Store from '@/store'
export default {
	name: 'SubOrdersToDispatch',
	setup() {
		const {
			selectOrderToDispatchColumns,
			orders,
			dispatchAddress,
			selectedDispatchNumber,
			keySubOrdersToDispatch,
			variablesQ2,
		} = useDispatchEditStore()
		// ! ------------------------------------------------------------------------------
		const optionsQ2 = reactive({
			fetchPolicy: 'no-cache',
		})
		const { refetch: refetchQ2 } = useQuery(
			GetOrdersToDispatchToSameDispatchAddressQuery,
			() => variablesQ2,
			optionsQ2,
		)
		onMounted(() => {
			refetchQ2().then((result) => {
				console.log('result.data', result.data)
				console.log('result.data.order_headers', result.data.order_headers)
				orders.value = result.data.order_headers
				dispatchAddress.value = result.data.addresses[0]
			})
		})
		// ! ------------------------------------------------------------------------------
		// * Eligible orders table
		const rows = computed(() => orders.value)
		const isSelectDisabled = ref(false)
		const isSelectThrottled = ref(false)
		const onSelect = (params) => {
			if (params.column.field === 'select') {
				console.log('params.row', params.row)
				mutationVariablesOrderToDispatch.order_number = params.row.order_number
				console.log(
					'mutationVariablesOrderToDispatch',
					mutationVariablesOrderToDispatch,
				)
				mutateAddOrderToDispatch().then(() => {
					const lines = params.row.order_heders_goods_transactions_rel
					console.log('lines', lines)
					let linesLength = lines.length
					for (let i = linesLength; i > 0; i--) {
						console.log('lines:', lines[i - 1])
						let item = lines[i - 1]
						mutationVariablesMutateStockCreditAmountMutation.company_id =
							Store.getters.getUserCurrentCompany
						mutationVariablesMutateStockCreditAmountMutation.stock_id =
							item.stock_id
						mutationVariablesMutateStockCreditAmountMutation.credit_amount =
							item.dispatch_amount
						mutateStockCreditAmount()
					}
				})
				isSelectDisabled.value = true
				isSelectThrottled.value = true
				setTimeout(() => {
					keySubOrdersToDispatch.value += 1
					isSelectDisabled.value = false
					isSelectThrottled.value = false
				}, 600)
			}
		}
		const onCellClick = (params) => {
			if (isSelectThrottled.value === false) {
				onSelect(params)
			} else {
				console.log('wait')
			}
		}
		const onRowDoubleClick = (params) => {
			onSelect(params)
		}
		// ! ------------------------------------------------
		// * AddOrderToDispatchMutation
		const mutationVariablesOrderToDispatch = reactive({
			dispatch_number: computed(() => selectedDispatchNumber.value),
			order_number: '',
		})
		const {
			mutate: mutateAddOrderToDispatch,
			// onDone: onDoneAddDispatchToInvoice,
		} = useMutation(AddOrderToDispatchMutation, () => ({
			variables: mutationVariablesOrderToDispatch,
		}))
		// *----------------------------------------------------------------------------------
		// * MutateStockCreditAmountMutation
		const mutationVariablesMutateStockCreditAmountMutation = reactive({
			company_id: 'symbol',
			stock_id: '',
			credit_amount: 0,
		})
		const {
			mutate: mutateStockCreditAmount,
			// onDone: onDoneSetInvoiceLocked,
		} = useMutation(MutateStockCreditAmountMutation, () => ({
			variables: mutationVariablesMutateStockCreditAmountMutation,
		}))
		// ! ---------------------------------------------------------
		return {
			// * Eligible Orders Table
			onCellClick,
			onRowDoubleClick,
			orders,
			rows,
			selectOrderToDispatchColumns,
			isSelectDisabled,
		}
	},
}
</script>

<style scoped></style>
