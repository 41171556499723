<template>
	<div class="margin-bottom-30 margin-top-10">
		<vue-good-table
			id="orders"
			:columns="selectedOrdersColumns"
			:rows="rows"
			styleClass="vgt-table striped bordered"
			theme="black-rhino"
			max-height="700px"
			:fixed-header="true"
			@on-row-dblclick="onRowDoubleClick"
			@on-cell-click="onCellClick"
			:pagination-options="{
				enabled: true,
				mode: 'records',
				perPage: 10,
				position: 'bottom',
				perPageDropdown: [5, 10, 15, 20],
				dropdownAllowAll: false,
				setCurrentPage: 1,
				jumpFirstOrLast: true,
				firstLabel: 'First',
				lastLabel: 'Last',
				nextLabel: 'next',
				prevLabel: 'prev',
				rowsPerPageLabel: 'Rows per page',
				ofLabel: 'of',
				pageLabel: 'page', // for 'pages' mode
				allLabel: 'All',
			}"
		>
			<template slot="table-row" slot-scope="props">
				<span v-if="props.column.field === 'remove'">
					<b-button class="is-small is-success" :disabled="isSelectDisabled">
						Remove
					</b-button>
				</span>
				<span v-if="props.column.field === 'preview'">
					<b-button class="is-small is-info">Preview</b-button>
				</span>
				<span v-else>
					{{ props.formattedRow[props.column.field] }}
				</span>
			</template>
		</vue-good-table>
	</div>
</template>

<script>
import useDispatchEditStore from '@/_srcv2/pages/dispatch/edit-dispatch/store/useDispatchEditStore.js'
import { computed, ref, reactive } from '@vue/composition-api'
import DispatchLinesSubscription from '@/_srcv2/pages/dispatch/edit-dispatch/graphql/DispatchLinesSubscription.graphql'
import RemoveOrderFromDispatchMutation from '@/_srcv2/pages/dispatch/edit-dispatch/graphql/RemoveOrderFromDispatchMutation.graphql'
import { useResult, useSubscription, useMutation } from '@vue/apollo-composable'
import MutateStockCreditAmountMutation from '@/_srcv2/pages/dispatch/graphql/mutateStockCreditAmount.mutation.graphql'
import Store from '@/store'
export default {
	name: 'SubOrdersSelected',
	setup() {
		const {
			selectedOrdersColumns,
			selectedOrders,
			selectedDispatchNumber,
			keySubOrdersToDispatch,
		} = useDispatchEditStore()
		// ! ------------------------------------------------------------------------------
		const { result } = useSubscription(DispatchLinesSubscription, () => ({
			dispatch_number: selectedDispatchNumber.value,
		}))
		selectedOrders.value = useResult(result, null, (data) => data.order_headers)
		// ! ------------------------------------------------------------------------------
		const mutationVariablesRemoveOrder = reactive({
			order_number: ref(''),
		})
		const {
			mutate: mutateRemoveOrder,
			// onDone: onDoneAddDispatchToInvoice,
		} = useMutation(RemoveOrderFromDispatchMutation, () => ({
			variables: mutationVariablesRemoveOrder,
		}))
		// ! ------------------------------------------------------------------------------
		// * MutateStockCreditAmountMutation
		const mutationVariablesMutateStockCreditAmountMutation = reactive({
			company_id: 'symbol',
			stock_id: '',
			credit_amount: 0,
		})
		const {
			mutate: mutateStockCreditAmount,
			// onDone: onDoneSetInvoiceLocked,
		} = useMutation(MutateStockCreditAmountMutation, () => ({
			variables: mutationVariablesMutateStockCreditAmountMutation,
		}))
		//! ------------------------------------------------------------------
		// * Selected orders table
		const rows = computed(() => selectedOrders.value)
		const isSelectDisabled = ref(false)
		const isSelectThrottled = ref(false)
		const onRemove = (params) => {
			if (params.column.field === 'remove') {
				console.log(params.row)
				mutationVariablesRemoveOrder.order_number = params.row.order_number
				mutateRemoveOrder().then(() => {
					const lines = params.row.order_heders_goods_transactions_rel
					console.log('lines', lines)
					let linesLength = lines.length
					for (let i = linesLength; i > 0; i--) {
						console.log('lines:', lines[i - 1])
						let item = lines[i - 1]
						mutationVariablesMutateStockCreditAmountMutation.company_id =
							Store.getters.getUserCurrentCompany
						mutationVariablesMutateStockCreditAmountMutation.stock_id =
							item.stock_id
						mutationVariablesMutateStockCreditAmountMutation.credit_amount =
							item.dispatch_amount * -1
						mutateStockCreditAmount()
					}
				})
				isSelectDisabled.value = true
				isSelectThrottled.value = true
				setTimeout(() => {
					keySubOrdersToDispatch.value += 1
					isSelectDisabled.value = false
					isSelectThrottled.value = false
				}, 600)
			}
		}
		const onCellClick = (params) => {
			if (isSelectThrottled.value === false) {
				onRemove(params)
			} else {
				console.log('wait')
			}
		}
		const onRowDoubleClick = (params) => {
			onRemove(params)
		}
		// ! ------------------------------------------------
		return {
			// * Eligible Orders Table
			onCellClick,
			onRowDoubleClick,
			selectedOrders,
			rows,
			selectedOrdersColumns,
			isSelectDisabled,
		}
	},
}
</script>

<style scoped></style>
