<!-- @format -->

<template>
	<div>
		<LayoutDefaultNew>
			<SubNavbar page="Dispatch"></SubNavbar>
			<div>
				<section class="hero is-danger margin-bottom-20">
					<div class="hero-body">
						<p class="title">Edit dispatch</p>
						<p class="subtitle">Please select the orders to dispatch</p>
					</div>
				</section>
				<section class="margin-bottom-20 margin-left-20">
					<p>
						<span class="text-bold">Dispatch Number:</span>
						&nbsp;{{ selectedDispatchNumber }}
					</p>
					<p>
						<span class="text-bold">Dispatch Date:</span>
						&nbsp;{{ selectedDispatchDate }}
					</p>
					<p>
						<span class="text-bold">OurCompany:</span>
						&nbsp;{{ ourCompany.toUpperCase() }}
					</p>
					<p>
						<span class="text-bold">Customer:</span>
						<span class="text-bold text-blue">
							&nbsp;{{ customer }} &nbsp; &nbsp;
						</span>
					</p>
					<p>
						<span class="text-bold">Dispatch Address:</span>&nbsp;
						{{ dispatchAddress.address_nickname }}
					</p>
					<p>{{ dispatchAddress.line_1 }}&nbsp;{{ dispatchAddress.line_2 }}</p>
					<p>
						{{ dispatchAddress.post_code }}&nbsp;<span class="text-bold">{{
							dispatchAddress.city
						}}</span>
					</p>
					<p>
						<span class="text-green text-bold"
							>{{ dispatchAddress.state }}
						</span>
						&nbsp;
						<span class="text-bold text-green">
							{{ dispatchAddress.country }}
						</span>
					</p>
					<p>
						<span class="text-bold"> Route id: </span> &nbsp;
						<span class="text-bold text-red">
							{{ dispatchAddress.route_id }}
						</span>
					</p>
				</section>
			</div>
			<div class="text-bold margin-top-20">
				Customer's orders waiting to dispatch
			</div>
			<div class="margin-bottom-30">
				<SubOrdersToDispatch :key="keySubOrdersToDispatch" />
			</div>
			<div class="text-bold margin-top-20">
				Selected orders to add to dispatch
			</div>
			<div class="margin-bottom-30">
				<SubOrdersSelected />
			</div>
			<div
				id="saveButtons"
				class="margin-bottom-30; buttons-container"
				style="
					background-color: rgb(76, 92, 122);
					padding-bottom: 30px;
					margin-top: -30px;
				"
			>
				<div>
					<b-button
						class="margin-top-30 margin-left-15 width-180"
						style="background-color: rgb(182, 90, 92); color: white"
						@click="onLeaveAsDraft"
						:disabled="disableButtons"
					>
						Leave as Draft
					</b-button>
					<b-button
						class="margin-top-30 margin-left-15 width-180"
						style="background-color: rgb(61, 196, 134); color: white"
						:disabled="disableObject.isSaveAndViewDisabled || disableButtons"
						@click="
							preventButtonDoubleClick(
								onSaveAndView,
								1000,
								disableObject,
								'onSave',
							)
						"
					>
						Save & Close
					</b-button>
					<b-button
						class="margin-top-30 margin-left-15 width-180"
						style="background-color: rgb(52, 136, 206); color: white"
						:disabled="disableObject.isSaveAndViewDisabled || disableButtons"
						@click="
							preventButtonDoubleClick(
								onSaveAndView,
								1000,
								disableObject,
								'onSaveAndView',
							)
						"
					>
						Save & View
					</b-button>
				</div>
			</div>
			<div
				id="cancelButton"
				class="margin-bottom-30; buttons-container"
				style="background-color: rgb(76, 92, 122); padding-bottom: 30px"
			>
				<b-button
					class="margin-left-15 width-180"
					style="background-color: rgb(241, 70, 104); color: white"
					@click="cancelTheDispatch"
					:disabled="!disableButtons"
				>
					Cancel The Dispatch
				</b-button>
				<div style="color: red; margin-top: 15px; margin-left: 15px">
					....to cancel the dispatch you must empty it first
				</div>
			</div>

			<div v-if="!!isModalVisible">
				<ModalDispatchFrame :key="keyModal" />
			</div>
		</LayoutDefaultNew>
	</div>
</template>
<script>
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar.vue'
import useDispatchEditStore from '@/_srcv2/pages/dispatch/edit-dispatch/store/useDispatchEditStore.js'
import SubOrdersToDispatch from '@/_srcv2/pages/dispatch/edit-dispatch/sub-components/SubOrdersToDispatch.vue'
import SubOrdersSelected from '@/_srcv2/pages/dispatch/edit-dispatch/sub-components/SubOrdersSelected.vue'
import { computed, reactive, onMounted, ref } from '@vue/composition-api'
// * -----------------------------------------------------------------------------
import SetDispatchLockedMutation from '@/_srcv2/pages/dispatch/graphql/SetDispatchLockedMutation.gql'
import CancelDispatchMutation from '@/_srcv2/pages/dispatch/edit-dispatch/graphql/CancelDispatchMutation.graphql'
import { useMutation } from '@vue/apollo-composable'
// * ------------------------------------------------------------------------------
import Router from '@/router'
import useProcessReportStore from '@/_srcv2/pages/_reports/create-report/useProcessReportStore.js'
import ModalDispatchFrame from '@/_srcv2/pages/_reports/get-data-and-report/dispatch/ModalDispatchFrame.vue'

export default {
	name: 'EditDispatch',
	components: {
		SubNavbar,
		ModalDispatchFrame,
		SubOrdersToDispatch,
		SubOrdersSelected,
	},
	setup() {
		const {
			ourCompany,
			customer,
			dispatchAddress,
			selectedDispatchNumber,
			selectedDispatchDate,
			// showGetDispatchReport,
			keySubOrdersToDispatch,
			selectedOrders,
		} = useDispatchEditStore()
		// ! -----------------------------------------------------------------------
		onMounted(() => {
			return 42
		})
		// ! -----------------------------------------------------------------------
		// * SetDispatchLockedMutation
		const mutationVariablesSetDispatchLocked = reactive({
			company_id: ourCompany.value,
			dispatch_number: computed(() => selectedDispatchNumber.value),
		})
		const {
			mutate: mutateSetDispatchLocked,
			// onDone: onDoneSetInvoiceLocked,
		} = useMutation(SetDispatchLockedMutation, () => ({
			variables: mutationVariablesSetDispatchLocked,
		}))
		// * ---------------------------------------------------------------------
		// * Cancel / Save / View the Dispatch
		const {
			mutate: cancelDispatch,
			// onDone: onDoneSetInvoiceLocked,
		} = useMutation(CancelDispatchMutation, () => ({
			variables: mutationVariablesSetDispatchLocked,
		}))
		// * ---------------------------------------------------------------------
		const cancelTheDispatch = () => {
			cancelDispatch().then(() => Router.push({ name: 'ViewDispatch' }))
		}
		// * ----------------------------------------------------------------------
		const {
			reportDocumentType,
			reportDocumentNumber, // ***
			reportDocumentOurCompany, // ***
			isSelectedDocumentLocked,
			isModalVisible,
			reportCustomer,
			reportDocumentDate,
			reportDocumentStatus,
			isSelectedDocumentReported,
		} = useProcessReportStore()
		// ! ------------------------------------------------------------
		const setProcessReportValues = () => {
			return new Promise((resolve, reject) => {
				if (selectedDispatchNumber.value !== null || undefined) {
					// ! ----------------------------------------------------------------------------------------------------------------------
					// *** Process Report Values
					reportDocumentType.value = 'dispatch'
					reportDocumentNumber.value = selectedDispatchNumber.value
					reportDocumentOurCompany.value = ourCompany.value
					isSelectedDocumentLocked.value = true
					isSelectedDocumentReported.value = true
					reportCustomer.value = computed(
						() =>
							`${customer.value.customer_id} - ${customer.value.customer_title} - ${customer.value.customer_nickname}`,
					)
					reportDocumentDate.value = computed(() => selectedDispatchDate.value)
					reportDocumentStatus.value = 'Dispatch is locked & not invoiced'
					resolve('ok')
				} else {
					const reason = new Error('The Dispatch Number could not be seen')
					reject(reason)
				}
			})
		}
		// ! -------------------------------------------------------------------------------------------------------------------------------
		const keyModal = ref(1)
		const getTheDispatch = () => {
			isModalVisible.value = true
			keyModal.value += 1
		} // * Open modal
		// ! -------------------------------------------------------------
		const onSaveAndView = (method) => {
			if (method === 'onSave') {
				mutateSetDispatchLocked().then(() =>
					Router.push({ name: 'ViewDispatch' }),
				)
			} else {
				mutateSetDispatchLocked()
					.then(() => setProcessReportValues())
					.then(() => getTheDispatch())
				// .then(() => (isModalVisible.value = true))
			}
		}
		// ! ---------------------------------------------------------------------
		const disableButtons = computed(() => {
			if (selectedOrders.value) {
				return selectedOrders.value.length === 0
			} else return false
		})
		const onLeaveAsDraft = () => {
			if (
				confirm(
					'Are you sure to leave as draft the dispatch \n To leave as draft the dispatch press "ok" \n To continue to edit dispatch press "cancel"',
				)
			) {
				Router.push({ name: 'ViewDispatch' })
				alert('You have leaved the dispatch as draft ')
			}
		}
		const preventButtonDoubleClick = (fn, delay, obj, method) => {
			fn(method)
			obj.isSaveAndViewDisabled = true
			setTimeout(() => (obj.isSaveAndViewDisabled = false), delay)
		}
		const disableObject = reactive({
			isSaveAndViewDisabled: false,
		})
		// * ------------------------------------------------------------
		return {
			ourCompany,
			customer,
			dispatchAddress,
			selectedDispatchNumber,
			selectedDispatchDate,
			onLeaveAsDraft,
			onSaveAndView,
			disableButtons,
			preventButtonDoubleClick,
			disableObject,
			isModalVisible,
			keySubOrdersToDispatch,
			keyModal,
			cancelTheDispatch,
		}
	},
}
</script>
<style>
.buttons-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	padding: 20 px;
}
</style>
